import waveLoading from '../utlis/waveLoading';
import { Component, createRef } from 'react';

export default class Loading extends Component {
    constructor(props) {
        super(props);
        this.wave = new waveLoading();
        this.progressEl = createRef();
    }
    componentDidMount() {
        this.wave.init({
            target: this.progressEl.current,
            color: '#f37977',
            lineWidth: 4,
            showText: true,
            textColor: '#fff',
            callback: () => {
                this.props.onFinished();
            },
        });
        this.wave.draw();
    }
    componentDidUpdate(props) {
        const { progress = 0 } = this.props;
        this.wave.setProgress(progress * 100);
    }
    componentWillUnmount() {
        this.wave.destory();
        this.wave = null;
    }

    render() {
        return (
            <div className="popup_loading">
                <div className="heart_wrap">
                    <canvas
                        width="80"
                        height="80"
                        ref={this.progressEl}
                    ></canvas>
                </div>
                <p>正在进入玩心领域...</p>
                <span className="netease_logo"></span>
            </div>
        );
    }
}
