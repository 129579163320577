import Normal from './Normal';
import {
    getActList,
    setActStatus,
    ACT_STATUS_TEXT,
    ACT_STATUS,
} from '../../methods/actManager';

import { useEffect, useState, useCallback } from 'react';
export default function ActList({
    onChanceChanged = () => {},
    showAlert,
    isShow,
    ...props
}) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let res = await getActList();
        res && setList(res);
        setLoading(false);
    };
    const getChance = useCallback(
        async ({ type, status }) => {
            if (status == ACT_STATUS.FINESHED) {
                let res = await setActStatus(type, ACT_STATUS.USED);
                if (res.code < 0) {
                    showAlert(res.msg);
                }
                await fetchData();
                onChanceChanged();
            }
        },
        [onChanceChanged, showAlert]
    );

    useEffect(() => {
        if (isShow) {
            setLoading(true);
            fetchData();
        }
    }, [isShow]);
    return (
        <Normal title="完成每日任务，获得更多抽奖机会" {...props}>
            {loading && <p className="loading">加载中...</p>}
            {!loading && (
                <ul className="act_list">
                    {list.map((act, index) => {
                        return (
                            <li key={index}>
                                <button
                                    className={`button_enable ${
                                        act.status == ACT_STATUS.UNFINESHED
                                            ? 'disabled'
                                            : ''
                                    }`}
                                    disabled={act.status == ACT_STATUS.USED}
                                    onClick={(e) => {
                                        getChance(act);
                                    }}
                                >
                                    {ACT_STATUS_TEXT[act.status]}
                                </button>
                                <span className="award_name">{act.name}</span>
                            </li>
                        );
                    })}
                </ul>
            )}
        </Normal>
    );
}
