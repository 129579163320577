import { useState, useEffect } from 'react';
export default function Award({
    awardInfo = {},
    getAward = () => {},
    onClose,
}) {
    const { award_name, award_id, result_index } = awardInfo;
    const [awardImg, setAwardImg] = useState(null);
    const isThanks = award_id == 45 || result_index == 4;
    useEffect(() => {
        if (isThanks) {
            setAwardImg('thanks');
            return;
        }

        let imgname =
            result_index == 3
                ? 'zhoubian'
                : result_index == 6
                ? 'libao'
                : award_id;
        const img = new Image();
        img.onload = () => {
            setAwardImg(`/images/award/${imgname}.png`);
        };
        img.onerror = () => {
            setAwardImg('blank');
        };
        img.src = `/images/award/${imgname}.png`;
    }, [award_id, result_index, isThanks]);
    const noImg = awardImg == 'thanks' || awardImg == 'blank';
    return (
        awardImg && (
            <div className="award_detail_wrap">
                {!isThanks && (
                    <>
                        <div className="award_bg">
                            {!noImg && <img src={awardImg} alt={award_name} />}
                            <p
                                className={`award_name ${
                                    noImg ? 'award_name_big' : ''
                                }`}
                            >
                                {isThanks ? '' : award_name}
                            </p>
                        </div>
                    </>
                )}
                {isThanks && <div className="thanks_pop"></div>}
                {/* {isThanks && <img src="/images/award/gift_thanks.png" />}
                {(!noImg || isThanks) && (
                    
                )} */}
                <a
                    href="javascript:void(0)"
                    onClick={() => {
                        !isThanks && getAward(awardInfo);
                        isThanks && onClose();
                    }}
                    className="button_orange"
                >
                    {isThanks ? '再去试试' : '前往去领取'}
                </a>
            </div>
        )
    );
}
