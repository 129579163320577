import { useCallback } from 'react';
import Normal from './Normal';
export default function Address({
    id = 0,
    onSubmit = () => {},
    showAlert,
    showConfirm,
    ...props
}) {
    const submitHandle = useCallback(
        async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const entries = formData.entries();
            const data = Object.fromEntries(entries);
            if (!data.receiver) {
                showAlert('请输入收件人');
                return;
            }
            if (!data.mobile) {
                showAlert('请输入手机号');
                return;
            }
            if (!data.address) {
                showAlert('请输入地址');
                return;
            }
            if (await showConfirm('填写后不可更改，是否继续提交')) {
                await onSubmit(data);
            }
            return false;
        },
        [onSubmit, showAlert, showConfirm]
    );
    return (
        <Normal title="我的奖品" {...props}>
            <form className="address_form" onSubmit={submitHandle}>
                <input type="hidden" name="id" value={id} />
                <div>
                    <label htmlFor="">收件人</label>
                    <input type="text" name="receiver" maxLength={10} />
                </div>
                <div>
                    <label htmlFor="">联系方式</label>
                    <input type="tel" name="mobile" maxLength={11} />
                </div>
                <div>
                    <label htmlFor="">收货地址</label>
                    <input type="text" name="address" maxLength={100} />
                </div>
                <p className="tip">
                    <span>
                        填写信息后不可修改，请确认收货
                        <br />
                        信息填写无误
                    </span>
                </p>
                <div className="alian_center">
                    <button className="button_confirm" type="submit"></button>
                </div>
            </form>
        </Normal>
    );
}
