const ua = navigator.userAgent;
const ualow = ua.toLowerCase();
const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
let mobile = false;
const host = window.location.host;
const search = window.location.search;
for (var v = 0; v < Agents.length; v++) {
    if (ua.indexOf(Agents[v]) > 0) {
        mobile = true;
        break;
    }
}
export const isLocal = /localhost|192|127/.test(host);
export const isDebug = isLocal || /\.dev/.test(host) || /debug=/.test(search);
// eslint-disable-next-line
export const isWx = ualow.match(/MicroMessenger/i) == 'micromessenger' || isLocal;
export const isPc = !mobile;
export const isMobile = mobile;
export const support_css3 = (function () {
    var div = document.createElement('div'),
        vendors = 'Ms O Moz Webkit'.split(' '),
        len = vendors.length;

    return function (prop) {
        if (prop in div.style) return true;

        prop = prop.replace(/^[a-z]/, function (val) {
            return val.toUpperCase();
        });

        while (len--) {
            if (vendors[len] + prop in div.style) {
                return true;
            }
        }
        return false;
    };
})();



