var Mock = require('mockjs');
Mock.setup({
    timeout: '1000-2000'
})
export default function mock() {
    Mock.mock('/api/?a=user', {
        code: 0,
        msg: '',
        data: {
            openid: 'testopenid',
            'count|0-4': 1
        }
    })
    Mock.mock('/api/?a=lottery', {
        code: 0,
        msg: '',
        data: {
            'award_id|1-45': 1,
            'award_type|1-3': 1,
            'award_name': '奖品名称'
        }
    })
    Mock.mock('/api/?a=address', {
        code: 0,
        msg: '',
        data: true
    })
    Mock.mock('/api/?a=log', {
        code: 0,
        msg: '',
        'data|1-10': [{
            award_id: 1,
            'award_type|1-3': 1,
            award_name: '奖品名称',
            'id|+1': 1,
            'cdk': 'xxxxxxxxxxx',
            'mobile': '',
            receiver: '',
            address: ""
        }]
    })
    Mock.mock('/api/?a=add_lottery_count', {
        code: 0,
        msg: '',
        data: true
    })
    Mock.mock('/api/?a=lottery_chances', {
        code: 0,
        msg: '',
        'data|1-2': [{
            openid: 1,
            'type|1-5': 1
        }]
    })
}