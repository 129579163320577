import { Component, createRef } from 'react';
import { isWx, isLocal } from '../utlis/env';
import '../css/music.scss';
export default class Share extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlay: false,
        };
        this.audio = createRef();
    }
    setStatus = () => {
        let audioEl = this.audio.current;
        if (!audioEl) {
            return;
        }
        this.setState({
            isPlay: !audioEl.paused,
        });
        window._hmt.push([
            '_trackEvent',
            'music',
            audioEl.paused ? 'play' : 'pause',
        ]);
    };
    play = () => {
        let audioEl = this.audio.current;
        audioEl && audioEl.play();
    };
    touchPlay = () => {
        this.play();
        document.removeEventListener('touchstart', this.touchPlay);
    };
    componentDidMount() {
        let audioEl = this.audio.current;
        if (!audioEl) {
            return;
        }
        audioEl.addEventListener('playing', this.setStatus);
        audioEl.addEventListener('pause', this.setStatus);
        if (isWx && typeof WeixinJSBridgeReady === 'undefined') {
            if (document.addEventListener) {
                document.addEventListener(
                    'WeixinJSBridgeReady',
                    () => {
                        this.play();
                    },
                    false
                );
            } else {
                document.addEventListener('touchstart', this.touchPlay);
            }
        } else {
            document.addEventListener('touchstart', this.touchPlay);
        }
    }
    componentWillUnmount() {
        let audioEl = this.audio.current;
        document.removeEventListener('touchstart', this.touchPlay);
        if (!audioEl) {
            return;
        }
        audioEl.removeEventListener('playing', this.setState);
        audioEl.removeEventListener('pause', this.setState);
    }
    handleClick = () => {
        let audioEl = this.audio.current;
        if (!audioEl) {
            return;
        }
        if (!audioEl.paused) {
            audioEl.pause();
        } else {
            audioEl.play();
        }
    };
    render() {
        const { src = '/music/bgm.mp3' } = this.props;
        return (
            <>
                <button
                    onClick={this.handleClick}
                    className={
                        'icon-music ' +
                        `${this.state.isPlay ? 'playing' : 'pause'}`
                    }
                ></button>

                <audio
                    className="bgm_audio"
                    ref={this.audio}
                    src={src}
                    loop
                    autoPlay={!isLocal}
                    preload={'true'}
                ></audio>
            </>
        );
    }
}
