import React, { Component } from 'react';
import giftRtx from '../../images/gift_rtx.png';
import giftWeimeng from '../../images/gift_weimeng.png';
import giftKeyboard from '../../images/gift_keyboard.png';
import giftGameAround from '../../images/gift_game_around.png';
import giftThanks from '../../images/gift_thanks.png';
import giftDrink from '../../images/gift_keke.png';
import giftGamePack from '../../images/gift_game_pack.png';
import giftPhone from '../../images/gift_phone.png';
import { lottery } from '../../api/index';

const giftList = [
    {
        img: giftRtx,
        name: '宁美国度',
        id: [12, 13, 14],
    },
    {
        img: giftWeimeng,
        name: '威猛先生清洁礼包',
        id: 4,
    },
    {
        img: giftKeyboard,
        name: '雷蛇游戏外设',
        id: [6, 7, 8, 9, 10, 11],
    },
    {
        img: giftGameAround,
        name: '游戏周边',
        id: (id) => {
            return id != 26 && id >= 15 && id <= 42;
        },
    },
    {
        img: giftThanks,
        name: '',
        id: 45,
    },
    {
        img: giftDrink,
        name: '阿华田饮品礼盒',
        id: 1,
    },
    {
        img: giftGamePack,
        name: '游戏礼包',
        id: [40],
    },
    {
        img: giftPhone,
        name: '荣耀50手机',
        id: 5,
    },
];

function Gift({ img, name, isSelected = false }) {
    return (
        <div className="gift_wrap">
            <img src={img} alt={name} />
            <p>{name}</p>
            {isSelected && <div className="isSelected"></div>}
        </div>
    );
}

const giftPos = [0, 1, 2, 7, 'start', 3, 6, 5, 4];
export default class Lottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curGiftIndex: -1,
            isLoading: false,
        };
        this.init();
    }
    init() {
        this.setState({
            curGiftIndex: -1,
            isLoading: false,
        });
        this.awardInfo = null;
        this.lotteryResult = -1;
        this.clock = null;
    }
    startLottery = async () => {
        if (!this.clock) {
            window._hmt.push(['_trackEvent', 'lottery', 'start']);
            let times = 0;
            this.setState({ isLoading: true });
            this.clock = setInterval(() => {
                let nextIndex = (this.state.curGiftIndex + 1) % 8;
                this.setState({ curGiftIndex: nextIndex });
                times++;
                if (
                    times > 8 * 2 &&
                    this.lotteryResult >= 0 &&
                    nextIndex == this.lotteryResult
                ) {
                    clearInterval(this.clock);
                    this.setState({ isLoading: false });
                    this.awardInfo.result_index = this.lotteryResult;
                    setTimeout(() => {
                        this.props.onGetGift(this.awardInfo);
                        this.init();
                    }, 1000);
                }
            }, 150);
            let result = 4;
            let res;
            try {
                res = await lottery();
                let award_id = parseInt(res?.data?.award_id);
                if (award_id) {
                    var giftItem = giftList.findIndex((item) => {
                        let idType = typeof item.id;
                        switch (idType) {
                            case 'function':
                                return item.id(award_id);
                            case 'object':
                                return item.id.includes(award_id);
                            default:
                                return award_id == item.id;
                        }
                    });
                    giftItem >= 0 && (result = giftItem);
                }
            } catch (error) {}
            this.lotteryResult = result;
            this.awardInfo = res?.data;
        }
    };
    componentWillUnmount() {
        if (this.clock) {
            clearInterval(this.clock);
            this.clock = null;
        }
    }
    render() {
        const {
            lotteryChance = 0,
            onClose,
            onClickViewMyGift,
            onClickMoreChance,
        } = this.props;
        const { curGiftIndex, isLoading } = this.state;
        return (
            <div className="lottery_wrap">
                <button className="popup_close" onClick={onClose}></button>
                <p className="lottery_chance">剩余抽奖次数:{lotteryChance}</p>
                <div className="gift_list">
                    {giftPos.map((posIndex, index) => {
                        if (posIndex == 'start') {
                            return (
                                <button
                                    disabled={
                                        !parseInt(lotteryChance) || isLoading
                                    }
                                    key="start_btn"
                                    onClick={this.startLottery}
                                    className="start_lottery"
                                >
                                    点击
                                    <br />
                                    抽奖
                                </button>
                            );
                        }
                        const gift = giftList[posIndex];
                        const { img, name } = gift;
                        return (
                            <Gift
                                key={name}
                                img={img}
                                name={name}
                                isSelected={curGiftIndex == posIndex}
                            />
                        );
                    })}
                </div>
                <div className="lottery_footer">
                    <button
                        className="button_purple"
                        onClick={onClickViewMyGift}
                    >
                        查看我的奖品
                    </button>
                    <button
                        onClick={onClickMoreChance}
                        className="button_orange"
                    >
                        获得更多抽奖机会
                    </button>
                </div>
                {/* {true && <GiftDetail onGetGift={onGetGift} />} */}
            </div>
        );
    }
}
