const BRANDS = [
    {
        name: 'leishe',
        link: 'https://shop.m.jd.com/?shopId=10285963&utm_source=iosapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=Wxfriends&ad_od=share&utm_user=plusmember&gx=RnEwx2ZeOmKMztRP--txDyvWB3rQRCFEmDbD'
    },
    {
        name: 'nm',
        link: 'https://nmsm.tmall.com/',
    },
    {
        name: 'honor',
        link: 'https://www.hihonor.com/cn/'
    },
    {
        name: 'sanxing',
        link: 'https://shop.m.jd.com/?shopId=1000000288.com'
    },
    {
        name: 't3',
        link: 'https://www.t3go.cn/'
    },
    {
        name: 'waimai',
        link: 'https://market.waimai.meituan.com/gd/single.html?el_biz=waimai&el_page=gundam.loader&gundam_id=3erBjc&activity_id=376781'
    },
    {
        name: 'ahuatian',
        link: 'https://shop.m.jd.com/?shopId=1000124462&utm_source=iosapp&utm_medium=appshare&utm_campaign=t_335139774&utm_term=CopyURL&ad_od=share&gx=RnE1lWMIYGLfztRP--txDCvnUo-rT3oN2Y1z'
    },




];
export default BRANDS;