/**
 * 横竖屏
 * @param {Object}
 */

export default function ForceHorizontal() {
    var rootElement = null;
    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;
    function setSize() {
        if (!rootElement) {
            return;
        }
        width = document.documentElement.clientWidth;
        height = document.documentElement.clientHeight;
        let realWidth, realHeight, realMarginTop, realMarginLeft, rotation;
        if (width < height) {
            realWidth = '100vh';
            realHeight = '100vw';
            realMarginTop = '-50vw';
            realMarginLeft = '-50vh';
            rotation = 90;
        }
        else {
            realWidth = '100vw';
            realHeight = '100vh';
            realMarginTop = '-50vh';
            realMarginLeft = '-50vw';
            rotation = 0;
        }
        rootElement.style.width = realWidth;
        rootElement.style.height = realHeight;
        rootElement.style.top = '50%';
        rootElement.style.left = '50%';
        rootElement.style.marginTop = realMarginTop;
        rootElement.style.marginLeft = realMarginLeft;
        rootElement.style.transform = `rotate(${rotation}deg)`;
        rootElement.style.transformOrigin = `50% 50%`;
        rootElement.style.position = 'absolute';
    }
    function onResize() {
        setTimeout(() => {
            setSize();
        }, 100);
    }
    var evt = "onorientationchange" in window ? "orientationchange" : "resize";
    this.init = (el) => {
        rootElement = el;
        setSize();
        window.addEventListener(evt, onResize, false);
    }
    this.destory = () => {
        window.removeEventListener(evt, onResize, false);
    }
}
