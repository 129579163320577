import { lotteryChances, addLotteryCount } from '../api/index'
import getToday from '../utlis/getToday';
export const ACT_STATUS = {
    UNFINESHED: 0,
    FINESHED: 1,
    USED: 2
}
export const ACT_STATUS_TEXT = {
    [ACT_STATUS.UNFINESHED]: "未完成",
    [ACT_STATUS.FINESHED]: "领取",
    [ACT_STATUS.USED]: "已领取",
}
export const ACT_TYPES = {
    'login': 1,
    'leyuan': 3,
    'shikong': 2,
    'guo': 4,
    'guangchang': 5
}
export const ACT_LINKS = {
    'leyuan': 'https://cg.163.com/?sourcetype=cj#/game/mobile?topic=%E7%BD%91%E6%98%93%E6%B8%B8%E6%88%8F',
    'shikong': 'https://cc.163.com/215232441/',
    'guo': 'https://hj.hj088.cn/game163/hj.php',
    'guangchang': 'https://weibo.com/netease163?is_all=1'
}

const ActList = [
    {
        name: '每日登录获取一次抽奖机会',
        type: 1,
        status: 0,
    },
    {
        name: '每日2:00PM—2:10PM在线观看直播',
        type: 2,
        status: 0,
    },
    {
        name: '参与云游戏试玩获取一次抽奖机会',
        type: 3,
        status: 0,
    },
    {
        name: '浏览国潮风尚馆获取一次抽奖机会',
        type: 4,
        status: 0,
    },
    {
        name: '点击参与微博活动获取一次抽奖机会',
        type: 5,
        status: 0,
    },
];
const today = getToday();
const actStatusKey = 'actStatus-' + today;
export async function getActList() {
    let actStatusList = _getActStatus();
    let res = await lotteryChances();
    if (!res?.data) {
        return false;
    }
    let gotChance = res.data;
    return ActList.map((act) => {
        actStatusList[act.type] && (act.status = actStatusList[act.type])
        let has = gotChance.some((chance) => {
            return parseInt(chance.type) == act.type;
        });
        has && (act.status = ACT_STATUS.USED);
        return act;
    });
}

function _getActStatus() {
    let actStatusList = window.localStorage.getItem(actStatusKey);
    if (actStatusList) {
        actStatusList = JSON.parse(actStatusList);
    }
    return actStatusList || {};
}
function _setActStatus(status) {
    window.localStorage.setItem(actStatusKey, JSON.stringify(status));
}
export async function setActStatus(type, status) {
    if (status == ACT_STATUS.USED) {
        let res = await addLotteryCount(type);
        return res;
    }
    let actStatusList = _getActStatus();
    actStatusList[type] = status;
    _setActStatus(actStatusList);
}