import React, { Component } from 'react';
// import { Transition } from 'react-transition-group';
// const duration = 200;
// const defaultStyle = {
//     transition: `all ${duration}ms ease-in-out`,
//     opacity: 0,
//     transform: `scale(0)`,
// };
// const transitionStyles = {
//     entering: { opacity: 1, transform: `scale(1)` },
//     entered: { opacity: 1, transform: `scale(1)` },
//     exiting: { opacity: 0, transform: `scale(0)` },
//     exited: { opacity: 0, transform: `scale(0)` },
// };
export default class Popup extends Component {
    componentWillReceiveProps(newProps, oldProps) {
        if (
            newProps.isShow != this.props.isShow &&
            newProps.isShow &&
            newProps.name
        ) {
            console.log('显示' + newProps.name);
            window._hmt.push(['_trackPageview', '/' + newProps.name]);
        }
    }
    render() {
        const {
            onClose,
            isShow,
            children,
            className = '',
            zIndex = 300,
            onClick = () => {},
        } = this.props;
        const inStyles = 'in-style';
        const outStyles = 'out-style';
        const curStyle = isShow ? outStyles : inStyles;
        return (
            <>
                <div
                    style={{
                        display: isShow ? 'block' : 'none',
                        zIndex: zIndex,
                    }}
                    onClick={onClose}
                    className="popup_mask"
                ></div>
                <div
                    onClick={onClick}
                    style={{ zIndex: zIndex + 10 }}
                    className={`popup_wrap ${className} ${curStyle}`}
                >
                    {children}
                </div>
            </>
        );
    }
}
