import { useEffect, useState, useCallback } from 'react';
function getScale() {
    const designSize = [1920, 1080];
    var screenWidth = document.documentElement.clientWidth;
    var screenHeight = document.documentElement.clientHeight;
    var currentFontSize;
    if (screenHeight < screenWidth) {
        currentFontSize = screenHeight * 100 / designSize[1];
        window.SCALE = currentFontSize / 100;
    }
    else {
        currentFontSize = screenWidth * 100 / designSize[1];
        window.SCALE = currentFontSize / 100;
    }
    document.documentElement.style.fontSize = currentFontSize + 'px';
    return window.SCALE;
}
export default function useRem() {
    const [scale, setScale] = useState(getScale());
    const setSize = useCallback(() => {
        // console.log(getScale());
        setScale(getScale());
    }, [setScale]);
    useEffect(() => {
        window.addEventListener('resize', setSize);
        window.addEventListener('pageShow', setSize)
        window.addEventListener('DOMContentLoaded', setSize)
        return () => {
            window.removeEventListener('resize', setSize);
            window.removeEventListener('pageShow', setSize)
            window.removeEventListener('DOMContentLoaded', setSize)
        }
    }, [setSize]);
    return scale;
}