import { Component, createRef } from 'react';
import ForceHorizontal from '../utlis/ForceHorizontal';
import Background from '../components/Background';
import Popup from '../components/Popup';
import slogan from '../images/slogan.png';
import { isWx, isDebug } from '../utlis/env';
import { getUserInfo, getLogs } from '../api/index';
import Lottery from '../components/Popup/Lottery';
import Award from '../components/Popup/Award';
import Cdk from '../components/Popup/Cdk';
import ColorPopup from '../components/Popup/Color';
import MyAward from '../components/Popup/MyAward';
import ActList from '../components/Popup/ActList';
import Confirm from '../components/Popup/Confirm';
import Guid from '../components/Popup/Guid';
import Loading from '../components/Loading';
import Share from '../components/Share';
import Music from '../components/Music';
import BRANDS from '../constant/BRANDS';
import PCQrcode from '../components/Popup/PcQrcode';
import {
    setActStatus,
    ACT_TYPES,
    ACT_LINKS,
    ACT_STATUS,
} from '../methods/actManager';
const AWARD_TYPES = {
    CDK: 1,
    ENTITY: 2,
    NONE: 3,
};

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.homeDom = createRef();
        this.isLoading = false;
        this.state = {
            showQrcode: false,
            userInfo: null,
            lotteryChance: 0,
            awardInfo: null,
            myAwardList: [],
            popupName: '',
            isShowLoading: !window.isShowedLoading,
            progress: 0,
            confirmProps: {
                text: '',
            },
            isShowTipText:
                isDebug || !window.localStorage.getItem('showedguid'),
            isLoaded: false,
        };
        this.confirmCallbackPool = [];
    }
    getUserInfo = async () => {
        let res = await getUserInfo();
        if (res) {
            this.setState({
                lotteryChance: res.count,
                userId: res.openid,
            });
            setActStatus(1, ACT_STATUS.FINESHED);
        }
    };
    async getMyAwardList() {
        let res = await getLogs();
        this.setState({
            myAwardList: res?.data || [],
        });
    }
    componentDidMount() {
        if (!window.isHomeShowed) {
            // console.log('显示home_page');
            // window._hmt.push(['_trackPageview', '/home_page']);
            window.isHomeShowed = true;
        }

        this.getUserInfo();
        this.forceHorizontal = new ForceHorizontal();
        this.forceHorizontal.init(this.homeDom.current);

        // this.showAlert("请确认是否提交");
    }
    componentWillUnmount() {
        this.forceHorizontal.destory();
        this.forceHorizontal = null;
    }
    showLottery = () => {
        if (!isWx) {
            this.setState({
                showQrcode: true,
            });
            return;
        }
        this.setState({
            popupName: 'lottery',
        });
    };
    showGuid = () => {
        this.showPopup('guid');
    };
    showPopup = (popupName) => {
        this.setState({ popupName: popupName });
    };
    hidePopup = () => {
        this.setState({ popupName: '' });
    };

    onGetGift = (award) => {
        this.getUserInfo();
        this.setState({
            awardInfo: award,
        });
        this.showPopup('award');
    };
    showMyGift = () => {
        this.showPopup('myaward');
    };
    getMoreChance = () => {
        this.showPopup('actlist');
    };
    getAward = (award) => {
        const { history } = this.props;
        if (award) {
            this.setState({
                awardInfo: award,
            });
        }
        console.log(award);
        switch (parseInt(award.award_type)) {
            case AWARD_TYPES.CDK:
                this.setState({ popupName: 'cdk' });
                break;
            case AWARD_TYPES.ENTITY:
                history.replace('/address', { awardInfo: award });
                break;
            default:
                this.hidePopup();
                break;
        }
    };

    onClickSection = (sectionName) => {
        this.showPopup(sectionName);
    };
    onColorPopupClick = (name) => {
        let type = ACT_TYPES[name];
        let link = ACT_LINKS[name];
        type && setActStatus(type, ACT_STATUS.FINESHED);
        if (!link) {
            this.showAlert('敬请期待');
        } else {
            window.location.href = link;
        }
    };
    showConfirm = (text) => {
        return new Promise((resolve) => {
            this.setState({
                confirmProps: {
                    isShow: true,
                    showCancle: true,
                    text,
                    onOk: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(true);
                    },
                    onCancle: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(false);
                    },
                },
            });
        });
    };
    showAlert = (text) => {
        return new Promise((resolve) => {
            this.setState({
                confirmProps: {
                    isShow: true,
                    showCancle: false,
                    text,
                    onOk: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(true);
                    },
                },
            });
        });
    };
    onBrandClick = (brand) => {
        window._hmt.push(['_trackEvent', 'brandLink', 'click', brand.link]);
        setActStatus(ACT_TYPES.leyuan, ACT_STATUS.FINESHED);
        if (!brand.link) {
            this.showAlert('敬情期待!');
            return;
        }
        setTimeout(() => {
            window.location.href = brand.link;
        }, 100);
    };
    render() {
        const {
            lotteryChance,
            awardInfo,
            popupName,
            confirmProps,
            isShowLoading,
            progress,
        } = this.state;
        const { scale } = this.props;
        const gameLogos1 = [];
        const gameLogos2 = [];
        const gameLogos3 = [];
        for (let i = 1; i <= 21; i++) {
            gameLogos1.push(
                <span key={i} className="logo_wrap" href="">
                    <img alt="game logo" src={`/images/games/game${i}.png`} />
                </span>
            );
        }
        for (let i = 22; i <= 42; i++) {
            gameLogos2.push(
                <span key={i} className="logo_wrap" href="">
                    <img alt="game logo" src={`/images/games/game${i}.png`} />
                </span>
            );
        }
        for (let i = 43; i <= 63; i++) {
            gameLogos3.push(
                <span key={i} className="logo_wrap" href="">
                    <img alt="game logo" src={`/images/games/game${i}.png`} />
                </span>
            );
        }
        return (
            <div className="home" ref={this.homeDom}>
                {this.state.showQrcode && (
                    <PCQrcode
                        onClose={() => {
                            this.setState({
                                showQrcode: false,
                            });
                        }}
                    />
                )}
                {isShowLoading && (
                    <Loading
                        progress={progress}
                        onFinished={() => {
                            if (this.state.isShowLoading) {
                                console.log('event:加载完成');
                                window._hmt.push([
                                    '_trackEvent',
                                    'loading',
                                    'done',
                                ]);
                            }

                            window.isShowedLoading = true;
                            this.setState({
                                isShowLoading: false,
                            });
                        }}
                    />
                )}
                <Background
                    scale={scale}
                    onClick={this.onClickSection}
                    isLoaded={this.state.isLoaded}
                    isShowTipText={this.state.isShowTipText}
                    onTipDone={() => {
                        window.localStorage.setItem('showedguid', true);
                        this.setState({
                            isShowTipText: false,
                        });
                    }}
                    onProgress={(progress) => {
                        this.setState({
                            progress,
                        });
                        progress === 1 &&
                            this.setState({
                                isLoaded: true,
                            });
                    }}
                />
                <img
                    className={`slogan animate ${
                        this.state.isLoaded && !this.state.isShowTipText
                            ? 'animate_out'
                            : 'animate_in'
                    }`}
                    src={slogan}
                    alt=""
                />
                {this.state.isLoaded && !this.state.isShowTipText && (
                    <>
                        <button
                            onClick={this.showLottery}
                            className="lottery_btn"
                        >
                            剩余抽奖次数: {lotteryChance}
                        </button>
                        <a
                            href="javascript:void(0)"
                            onClick={this.showGuid}
                            className="guide_btn"
                        >
                            逛展攻略
                        </a>
                        <Share />
                        <Music />
                        <Popup
                            isShow={popupName == 'lottery'}
                            name="lottery_page"
                        >
                            <Lottery
                                onClose={this.hidePopup}
                                lotteryChance={lotteryChance}
                                onGetGift={this.onGetGift}
                                onClickViewMyGift={this.showMyGift}
                                onClickMoreChance={this.getMoreChance}
                            />
                        </Popup>

                        <Popup
                            className={'award_result_page'}
                            isShow={awardInfo && popupName == 'award'}
                            name="award_result_page"
                        >
                            {awardInfo && (
                                <Award
                                    awardInfo={awardInfo}
                                    getAward={this.getAward}
                                    onClose={this.hidePopup}
                                />
                            )}
                        </Popup>

                        <Popup isShow={popupName == 'cdk'} name="cdk_page">
                            <Cdk {...awardInfo} onClose={this.hidePopup} />
                        </Popup>

                        <Popup
                            isShow={popupName == 'guangchang'}
                            name={'guangchang_page'}
                        >
                            <ColorPopup
                                className={'popup_guangchang'}
                                name={'guangchang'}
                                onClose={this.hidePopup}
                                onClick={this.onColorPopupClick}
                            />
                        </Popup>
                        <Popup isShow={popupName == 'guo'} name={'guo_page'}>
                            <ColorPopup
                                className={'popup_guo'}
                                name={'guo'}
                                onClose={this.hidePopup}
                                onClick={this.onColorPopupClick}
                            />
                        </Popup>
                        <Popup
                            isShow={popupName == 'shikong'}
                            name={'shikong_page'}
                        >
                            <ColorPopup
                                className={'popup_shikong'}
                                name={'shikong'}
                                onClose={this.hidePopup}
                                onClick={this.onColorPopupClick}
                            />
                        </Popup>
                        <Popup
                            isShow={popupName == 'pingpai'}
                            name={'pingpai_page'}
                        >
                            <ColorPopup
                                showBtn={false}
                                className={'popup_pingpai'}
                                name={'pingpai'}
                                show
                                onClose={this.hidePopup}
                                onClick={this.onColorPopupClick}
                            >
                                <div className="brand_list">
                                    {BRANDS.map((brand) => {
                                        return (
                                            <button
                                                key={brand.name}
                                                onClick={() => {
                                                    this.onBrandClick(brand);
                                                }}
                                            >
                                                {brand.name}
                                            </button>
                                        );
                                    })}
                                </div>
                                <p className="tip_text text_shadow">
                                    更多品牌好礼关注网易游戏微博
                                </p>
                            </ColorPopup>
                        </Popup>
                        <Popup
                            isShow={popupName == 'leyuan'}
                            name="leyuan_page"
                        >
                            <ColorPopup
                                onClick={() => {
                                    this.onColorPopupClick('leyuan');
                                }}
                                showBtn={true}
                                className="popup_leyuan"
                                name={popupName}
                                onClose={this.hidePopup}
                            >
                                <div className="game_logo_wrap">
                                    <div>{gameLogos1}</div>
                                    <div>{gameLogos2}</div>
                                    <div>{gameLogos3}</div>
                                </div>
                                <p className="tip_text text_shadow">
                                    左右滑动参看更多游戏
                                </p>
                            </ColorPopup>
                        </Popup>
                        <Popup
                            isShow={popupName == 'myaward'}
                            name="myaward_page"
                        >
                            <MyAward
                                isShow={popupName == 'myaward'}
                                onGetAward={this.getAward}
                                onClose={this.hidePopup}
                            />
                        </Popup>
                        <Popup
                            isShow={popupName == 'actlist'}
                            name="actlist_page"
                        >
                            <ActList
                                isShow={popupName == 'actlist'}
                                onChanceChanged={this.getUserInfo}
                                onClose={this.hidePopup}
                                showAlert={this.showAlert}
                            />
                        </Popup>
                        <Popup
                            className="popup_confirm_outer"
                            isShow={confirmProps.isShow}
                            zIndex={400}
                        >
                            <Confirm
                                {...confirmProps}
                                onOk={confirmProps.onOk}
                                onCancle={confirmProps.onCancle}
                            />
                        </Popup>
                        <Popup isShow={popupName == 'guid'} name="guid_page">
                            <Guid onClose={this.hidePopup} />
                        </Popup>
                    </>
                )}
            </div>
        );
    }
}
