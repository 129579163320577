import React, { useEffect, useRef } from 'react';
import ClipboardJS from 'clipboard';
import Normal from './Normal';
export default function Cdk({ award_name = '', cdk = '', ...props }) {
    const copyBtnRef = useRef();
    useEffect(() => {
        let clipboard = new ClipboardJS(copyBtnRef.current, {
            text: () => cdk,
        });
        clipboard.on('success', function (e) {
            alert('复制成功');
        });
        return () => {
            clipboard.destroy();
            clipboard = null;
        };
    }, [cdk]);
    return (
        <Normal {...props} title="我的奖品" className="cdk_wrap">
            <p>
                恭喜获得{award_name}礼包一份，您的兑换码是:{cdk}
                ，赶快领取专属活动奖励吧！
            </p>
            <input readOnly id="ckd_input" hidden type="text" value={cdk} />
            <div className="alian_center">
                <button ref={copyBtnRef} className="button_copy"></button>
            </div>
        </Normal>
    );
}
