import QRCode from 'qrcode.react';
import Popup from './index';
export default function PcQrcode({ onClose }) {
    const location = window.location;
    console.log(onClose);
    return (
        <Popup isShow={true} onClick={onClose}>
            <div className="popup_qrcode" onClick={onClose}>
                <div className="qrcode_wrap">
                    <QRCode
                        includeMargin={true}
                        size={256}
                        value={`${location.protocol}//${location.host}${location.pathname}?from='pcqrcode'`}
                    />
                </div>
            </div>
        </Popup>
    );
}
