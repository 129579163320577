import { useState, useEffect } from 'react';
import SwiperCore, { Pagination, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import guid1 from '../../images/guid1.png';
import guid2 from '../../images/guid2.png';
import guid3 from '../../images/guid3.png';
SwiperCore.use([Pagination, Controller]);
export default function Guid({ onClose }) {
    const [controlledSwiper, setControlledSwiper] = useState(null);
    const mql = window.matchMedia('(orientation: portrait)');
    const [isShu, setIsShu] = useState(mql.matches);
    const handleOrientationChange = () => {
        setIsShu(mql.matches);
    };
    useEffect(() => {
        // eslint-disable-next-line
        mql.addListener(handleOrientationChange);
        return () => {
            // eslint-disable-next-line
            mql.removeListener(handleOrientationChange);
        };
        // eslint-disable-next-line
    }, [handleOrientationChange]);
    return (
        <div className="popup_guid">
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination
                onSwiper={setControlledSwiper}
            >
                <SwiperSlide>
                    <img src={guid1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={guid2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={guid3} alt="" />
                </SwiperSlide>
            </Swiper>

            <Swiper
                className="clone_swiper"
                slidesPerView={1}
                direction={isShu ? 'vertical' : 'horizontal'}
                controller={{ control: controlledSwiper }}
            >
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
                <SwiperSlide></SwiperSlide>
            </Swiper>

            <button onClick={onClose} className="popup_close"></button>
        </div>
    );
}
