import { useState, useEffect } from 'react';
// import design from '../images/design.png';
import '../css/background.scss';

import guangchang from '../images/section_guangchang.png';
import guo from '../images/section_guo.png';
import leyuan from '../images/section_leyuan.png';
import pingpai from '../images/section_pingpai.png';
import shikong from '../images/section_shikong.png';
import cloud_left from '../images/cloud_left.png';
import cloud_right from '../images/cloud_right.png';
// import design from '../images/design.png';
// import bg from '../images/bg.jpg';
const sectionImgList = [
    {
        name: 'guangchang',
        img: guangchang,
        className: 'up_down',
        style: {
            left: '2.9rem',
            bottom: '1rem',
        },
        tipText: '热爱广场',
    },

    {
        className: 'up_down delay3',
        name: 'leyuan',
        img: leyuan,
        style: { right: '9%', bottom: '0.9rem' },
        tipText: '趣享云乐园',
    },
    {
        className: 'up_down delay2',
        name: 'pingpai',
        img: pingpai,
        style: {
            bottom: 0,
            right: 0,
        },
        tipText: '品牌show场',
    },
    {
        className: 'up_down delay1',
        name: 'shikong',
        img: shikong,
        style: {
            bottom: 0,
        },
        tipText: '时空穿梭站',
    },
    {
        className: 'up_down delay2',
        name: 'guo',
        style: {
            bottom: 0,
            left: '26%',
        },
        img: guo,
        tipText: '国潮风尚馆',
    },
];
function getShijiHeight(scale) {
    const screenWidth = document.documentElement.clientWidth;
    const screenHeight = document.documentElement.clientHeight;
    const realWidth = screenWidth > screenHeight ? screenWidth : screenHeight;
    const yinggaiWidth = parseInt(1920 * scale);
    const shijiScale = yinggaiWidth / realWidth;
    const shijiHeight = 10.8 / shijiScale + 'rem';
    return shijiHeight;
}
export default function Background({
    scale = 1,
    onProgress = () => {},
    onClick = () => {},
    isShowTipText = false,
    isLoaded = false,
    onTipDone = () => {},
}) {
    const [loadedSection, setLoadedSection] = useState([]);
    const [shijiHeight, setShijiHeight] = useState(getShijiHeight(scale));
    const [curTipIndex, setCurTipIndex] = useState(sectionImgList.length - 1);
    const isShowTip = isLoaded && isShowTipText && curTipIndex >= 0;
    useEffect(() => {
        let loaded = 0;
        var promiseArr = sectionImgList.map((imgSrc) => {
            return new Promise((resolve, reject) => {
                let sectionImg = new Image();
                sectionImg.onload = () => {
                    loaded++;
                    onProgress(loaded / sectionImgList.length);
                    resolve(sectionImg);
                };
                sectionImg.onerror = () => {
                    loaded++;
                    onProgress(loaded / sectionImgList.length);
                    resolve(sectionImg);
                };
                sectionImg.src = imgSrc.img;
                sectionImg.keyName = imgSrc.name;
                sectionImg.className = imgSrc.name + ' ' + imgSrc.className;
                sectionImg.styles = imgSrc.style;
            });
        });

        Promise.all(promiseArr).then((res) => {
            setLoadedSection(res);
        });
        setShijiHeight(getShijiHeight(scale));
        // eslint-disable-next-line
    }, [setLoadedSection, scale]);
    return (
        <div
            className="background"
            onClick={() => {
                if (curTipIndex === 0) {
                    onTipDone();
                }
                if (isShowTip) {
                    setCurTipIndex(curTipIndex - 1);
                }
            }}
        >
            {/* <img className="bg" src={bg} alt="" /> */}

            {isShowTip && (
                <>
                    <div className="click-tip"></div>
                    <button
                        className="icon-skip"
                        onClick={() => {
                            setCurTipIndex(-1);
                            onTipDone();
                        }}
                    ></button>
                    <div
                        className="tip_bg"
                        style={{
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0,0,0,0.8)',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: (curTipIndex + 1) * 10 - 1,
                        }}
                    ></div>
                    {sectionImgList[curTipIndex]?.tipText && (
                        <p className="guid_tip_text">
                            {sectionImgList[curTipIndex]?.tipText}
                        </p>
                    )}
                </>
            )}
            <div
                className="inner"
                style={{
                    height: shijiHeight,
                    bottom: 0,
                }}
            >
                {isLoaded && (
                    <>
                        <img
                            alt=""
                            className="cloud_left left_right delay1"
                            src={cloud_left}
                        />
                        <img
                            alt=""
                            className="cloud_right left_right delay2"
                            src={cloud_right}
                        />
                    </>
                )}
                {loadedSection.map((section, index) => {
                    return (
                        <img
                            key={section.className}
                            alt=""
                            className={section.className}
                            style={{
                                ...section.styles,
                                width: (section.width / 1920) * 100 + '%',
                                zIndex: (index + 1) * 10,
                            }}
                            src={section.src}
                        />
                    );
                })}
                {!isShowTip &&
                    loadedSection.map((section) => {
                        return (
                            <div
                                key={section.keyName}
                                onClick={() => {
                                    onClick(section.keyName);
                                }}
                                className={`hotarea hotarea_${section.keyName}`}
                            ></div>
                        );
                    })}
                <div
                    className={`hotarea hotarea_guo2`}
                    onClick={() => {
                        onClick('guo');
                    }}
                ></div>
            </div>
        </div>
    );
}
