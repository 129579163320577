import { Component } from 'react';
import shareImg from '../images/share-img.jpeg';
import '../css/share.scss';
export default class Share extends Component {
    constructor() {
        super();
        this.state = {
            isShow: false,
        };
    }
    handleClick = () => {
        this.setState({
            isShow: !this.state.isShow,
        });
    };
    componentWillReceiveProps(newProps) {
        if (newProps.isShow && !this.props.isShow) {
            console.log('显示share_page');
            window._hmt.push(['_trackPageview', '/share_page']);
        }
    }
    render() {
        return (
            <>
                <button
                    onClick={this.handleClick}
                    className="icon-share"
                ></button>
                {this.state.isShow && (
                    <div onClick={this.handleClick} className="share-img">
                        <img alt="share" src={shareImg} />
                    </div>
                )}
            </>
        );
    }
}
