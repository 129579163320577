import { MemoryRouter as Router, Route } from 'react-router-dom';
import Home from './views/Home';
import PC from './views/PC';
import Address from './views/Address';
import './css/App.scss';
import './css/reset.css';
import { isWx, isPc, isDebug } from './utlis/env'
import useRem from './hooks/useRem';
import VConsole from 'vconsole';
import { login } from './api/index';
import setShare from './methods/setShare';
import { shareConfig } from './config.js';
// isWx && login();
// isWx && setShare(shareConfig);
isDebug && new VConsole();

function App() {
    const scale = useRem();
    const HomePage = Home;
    return (
        <div className="App">
            <Router >
                <Route exact={true} path="/" render={props => (
                    // pass the sub-routes down to keep nesting
                    <HomePage scale={scale} {...props} />
                )} >
                </Route>
                <Route path="/address" component={Address}></Route>
            </Router>
        </div>
    );
}

export default App;
