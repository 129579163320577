import { Component } from 'react';

import Popup from '../components/Popup/index';
import Confirm from '../components/Popup/Confirm';
import Address from '../components/Popup/Address';
import { saveAddress } from '../api/index';

export default class AddressPage extends Component {
    constructor(props) {
        super(props);
        const { location } = props;
        const awardInfo = location?.state?.awardInfo;
        if (!awardInfo) {
            this.hidePopup();
        }
        this.state = {
            awardInfo,
            confirmProps: {
                text: '',
            },
        };
    }
    componentDidMount() {
        console.log('显示address_page');
        window._hmt.push(['_trackPageview', '/address_page']);
    }
    onSubmit = async (address) => {
        let res = await saveAddress(address);
        if (res?.code == 0) {
            await this.showAlert('提交成功，我们会尽快给您发货！');
            await this.hidePopup();
        } else {
            await this.showAlert(res.msg || '提交失败,请稍后重试！');
        }
    };
    showConfirm = (text) => {
        return new Promise((resolve) => {
            this.setState({
                confirmProps: {
                    isShow: true,
                    showCancle: true,
                    text,
                    onOk: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(true);
                    },
                    onCancle: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(false);
                    },
                },
            });
        });
    };
    showAlert = (text) => {
        return new Promise((resolve) => {
            this.setState({
                confirmProps: {
                    isShow: true,
                    showCancle: false,
                    text,
                    onOk: () => {
                        this.setState({
                            confirmProps: { isShow: false },
                        });
                        resolve(true);
                    },
                },
            });
        });
    };
    hidePopup = () => {
        this.props.history.replace('/');
    };
    render() {
        const { confirmProps, awardInfo } = this.state;
        return (
            <div className="design_bg">
                <Popup isShow={true}>
                    <Address
                        showAlert={this.showAlert}
                        showConfirm={this.showConfirm}
                        onSubmit={this.onSubmit}
                        {...awardInfo}
                        onClose={this.hidePopup}
                    />
                </Popup>
                <Popup
                    className="popup_confirm_outer"
                    isShow={confirmProps.isShow}
                    zIndex={400}
                >
                    <Confirm
                        {...confirmProps}
                        onOk={confirmProps.onOk}
                        onCancle={confirmProps.onCancle}
                    />
                </Popup>
            </div>
        );
    }
}
