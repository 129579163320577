import axios from "axios";
import { isWx } from "../utlis/env";
import mock from './mock';
const isLocal = /localhost|192|127/.test(window.location.host);
isLocal && mock();
const apiUrl = '/api/'
var userInfo = null;
async function _request(action, type = 'get', data = {}) {
    alert("活动已关闭");
    var postData;
    if (type.toLowerCase() == 'post') {
        postData = new URLSearchParams();
        for (let i in data) {
            postData.append(i, data[i]);
        }
    }
    const url = action.indexOf('/') > -1 ? `${apiUrl}${action}` : `${apiUrl}?a=${action}`;
    const res = await axios({
        method: 'post',
        url,
        data: type == 'post' && postData,
        params: type == 'get' && data
    });
    return res?.data || { code: -100, msg: '请求失败，请稍后重试' };
}
/** 抽奖 */
export function lottery() {
    return isWx && _request('lottery', 'post',);
}

/** 登录 */
export async function login() {
    return;
    if (isWx) {
        if (!await getUserInfo()) {
            const callbackUrl = encodeURIComponent(window.location.href);
            const host = '';
            window.location.replace(`${host}/api/login.php?redirect_url=${callbackUrl}`);
        }
    }
    return false;
}

export async function getUserInfo() {
    if (!isWx) {
        return false;
    }

    let res = await _request('user', 'get');
    userInfo = res.code == 0 && res?.data;

    return userInfo;
}

export async function getLogs() {
    return isWx && await _request('log', 'get');
}

export async function addLotteryCount(type) {
    return isWx && await _request('add_lottery_count', 'post', { type });
}

export async function lotteryChances() {
    return isWx && await _request('lottery_chances', 'get');
}

export async function saveAddress(address) {
    return isWx && await _request('address', 'post', address);
}

export async function getJsSign() {
    return isWx && await _request('/jssign.php', 'get', { url: window.location.href });
}
