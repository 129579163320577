import Normal from './Normal';
export default function Confirm({
    showCancle = true,
    onOk = () => {},
    text = '',
    title = '提示',
    onCancle = () => {},
    ...props
}) {
    return (
        <Normal
            className="popup_confirm"
            title={title}
            {...props}
            showClose={false}
        >
            <div className="confirm_text">{text}</div>
            <div className="alian_center button_wrap">
                <button
                    className="button_confirm"
                    onClick={onOk}
                    type="button"
                ></button>
                {showCancle && (
                    <button
                        onClick={onCancle}
                        className="button_cancle"
                        type="button"
                    ></button>
                )}
            </div>
        </Normal>
    );
}
