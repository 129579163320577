import { getLogs } from '../../api/index';
import { useState, useEffect } from 'react';
import Normal from './Normal';
export default function MyAward({ onGetAward = () => {}, isShow, ...props }) {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const fetchData = async () => {
        let res = await getLogs();
        setLoading(false);
        setList(res?.data || []);
    };
    useEffect(() => {
        if (isShow) {
            fetchData();
        }
    }, [isShow]);
    return (
        <Normal title="我的奖品" {...props}>
            {loading && <p className="loading">加载中...</p>}
            {!loading && list.length == 0 && (
                <p className="loading">没有中奖记录</p>
            )}
            {!loading && list.length > 0 && (
                <ul className="my_award_list">
                    {list.map((award) => {
                        let isGot = award.award_type == 2 && award.mobile;
                        return (
                            award.award_type &&
                            award.award_type != 3 && (
                                <li key={award.id}>
                                    <button
                                        className="button_enable"
                                        disabled={isGot}
                                        onClick={() => {
                                            onGetAward(award);
                                        }}
                                    >
                                        {isGot ? '已领取' : '领取'}
                                    </button>
                                    <span className="award_name oneline">
                                        {award.award_name}
                                    </span>
                                </li>
                            )
                        );
                    })}
                </ul>
            )}
        </Normal>
    );
}
