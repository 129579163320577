export default function Normal({
    title = '标题',
    children,
    className = '',
    onClose = () => {},
    showClose = true,
}) {
    return (
        <div className={`popup_normal ${className}`}>
            {showClose && (
                <button onClick={onClose} className="popup_close"></button>
            )}
            <p
                className={`popup_title ${
                    title.length > 5 ? 'popup_title_big' : 'popup_title_small'
                }`}
            >
                {title}
            </p>
            <div className="popup_inner">{children}</div>
            <div className="popup_footer"></div>
        </div>
    );
}
