import shikong from '../../images/popup_shikong.png';
import pingpai from '../../images/popup_pingpai.png';
import leyuan from '../../images/popup_leyuan.png';
import guangchang from '../../images/popup_guangchang.png';
import guo from '../../images/popup_chao.png';
const popupBgList = {
    guo,
    shikong,
    pingpai,
    leyuan,
    guangchang,
};
export default function Color({
    name = '',
    buttonText = '点击查看',
    onClick = () => {},
    className = '',
    onClose = () => {},
    children,
    showBtn = true,
}) {
    return (
        <div
            style={{
                backgroundImage: `url(${popupBgList[name]||''})`,
            }}
            className={`popup_color ${className}`}
        >
            <button onClick={onClose} className="popup_close"></button>
            <div className="popup_inner">{children}</div>
            {showBtn && (
                <div className="button_wrap">
                    <button
                        onClick={() => {
                            onClick(name);
                        }}
                        className="button_click_view"
                    >
                        {buttonText}
                    </button>
                </div>
            )}
        </div>
    );
}
